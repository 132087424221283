<template>						
    <v-item-group :model="value" @change="output" :multiple="multiple">
        <v-item
            v-slot="{ toggle, active }"
            v-for="option in options"
            :key="option.id"
            :value="option.id"
        >
            <v-chip
                class="pa-4 ma-1 white--text"
                @click="toggle"
                :color="value.includes(option.id) ? 'primary' : 'grey'"
                >
                {{ option.name }}</v-chip
            >
        </v-item>
    </v-item-group>
</template>

<script>
export default {
	name: "ToggleCategory",
	props: {
        value: {type: [String, Array] },
        multiple: {type: Boolean, default: false},
		id: { type: String },
        storeModule: { type: String }

	},
	data: () => {
		return {
		};
	},
	computed: {
		category() {
			return this.$store.state[this.storeModule].data				
		},
        options(){
            const self = this;
            if( !this.category || !this.category.options ){
                return [];
            }
            return this.category.options.map( o => self.$store.state.categoryOptions.data[o] ).filter( o => o);
        }
	},
    methods: {
        output(value){
            this.$emit("input", value )
        }
    }
};
</script>
