<template>
	<v-sheet>
        <h2 class="mb-5">Organisation Profile</h2>
        <v-row>
            <v-col>
                <v-text-field
                    large
                    :label="$t('organisation.name')"
                    v-model="organisation.name"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="9">
                <v-row class="mb-8">
                    <v-col>
                        <p>
                            {{ $t("organisation.service") }}
                        </p>
                        <toggle-category
                            v-model="organisation['service']"
                            id="service"
                            storeModule="services"
                            multiple
                        ></toggle-category>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="12" md="6">
                        <v-textarea
                            rows="4"
                            :label="$t('organisation.address')"
                            v-model="organisation.address"
                        ></v-textarea>
                        <v-text-field
                            :label="$t('organisation.city')"
                            v-model="organisation.city"
                        ></v-text-field>
                        <v-text-field
                            :label="$t('organisation.postcode')"
                            v-model="organisation.postcode"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            append-icon="mdi-phone"
                            :label="$t('organisation.phone')"
                            v-model="organisation.phone"
                        ></v-text-field>
                        <v-text-field
                            append-icon="mdi-email"
                            :label="$t('organisation.url')"
                            v-model="organisation.url"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <p class="text-right">
                    <v-btn @click="save" color="primary">{{ $t("buttons.save") }}</v-btn>
                </p>
            </v-col>
            <v-col cols="12" md="3">
                <organisation-stats></organisation-stats>
                <change-lead-respondent></change-lead-respondent>
            </v-col>
        </v-row>
        <mw-dialog
            v-model="flag"
            small
            submitBtn
            @submit="flag = false"
        >
            <template v-slot:dialog-body>
                <p>{{ $t("organisation.correct_services") }}</p>
            </template>
        </mw-dialog>
		<v-snackbar color="primary" v-model="saved" text>{{
			$t("misc.saved")
		}}</v-snackbar>
	</v-sheet>
</template>
<script>
import OrganisationStats from "@c/profile/OrganisationStats"
import ChangeLeadRespondent from "@c/profile/ChangeLeadRespondent"
import ToggleCategory from "@c/ui/ToggleCategory.vue";
import MwDialog from '@c/ui/MwDialog.vue';
export default {
	name: "OrganisationProfile",
	props: {
		flag: { type: Boolean },
	},
	data: () => {
		return {
			saved: false,
		};
	},
	computed: {
		organisation() {
			return this.$store.state.organisation.data;
		},
	},
	components: {
        MwDialog,
		ToggleCategory,
        OrganisationStats, 
        ChangeLeadRespondent
	},
	methods: {
		save() {
			const self = this;
			const update = {
				"service": self.organisation["service"] || [],
				name: self.organisation.name || "",
				address: self.organisation.address || "",
				city: self.organisation.city || "",
				postcode: self.organisation.postcode || "",
				phone: self.organisation.phone || "",
				url: self.organisation.url || "",
			};
			self.$store.dispatch("organisation/patch", update).then(() => {
				self.saved = true;
			});
		},
	},
};
</script>
